<template>
    <div class="misc-wrapper">
        <b-link class="brand-logo">
            <h2 class="brand-text text-primary ml-1">
                MikroBilling
            </h2>
        </b-link>

        <div class="misc-inner p-2 p-sm-3">
            <div class="w-100 text-center" v-if="paymentCompletedInfo">
                 <h1 :class="statusVariant(paymentCompletedInfo.payment_status)">
                    Payment {{ statusMessage(paymentCompletedInfo.payment_status) }}!
                </h1>
                <p class="mb-2" v-if="paymentCompletedInfo.payment_status == 'VALID' || paymentCompletedInfo.payment_status == 'SUCCESS'">
                    Successfully !!! Paid Amount {{ paymentCompletedInfo.paid_amount }} and Transaction ID: {{ paymentCompletedInfo.transaction_id}}
                </p>
                <p class="mb-2" v-else>
                    Sorry! Your transaction has failed
                 </p>

                <b-button
                    variant="primary"
                    class="mb-1 btn-sm-block"
                    :to="loginRoute()"
                >Back to Home</b-button>
                <b-img
                    fluid
                    :src="imgUrl"
                    alt="Not authorized page"
                />
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg, BButton } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { PAYMENT_DETAILS } from "@core/services/api";

export default {
    components: {
        BLink, BImg, BButton, VuexyLogo,
    },
    data() {
        return {
            downImg: require('@/assets/images/pages/not-authorized.svg'),
            paymentCompletedInfo:null,
        }
    },
    computed: {
        statusVariant() {
            const statusColor = {
                /* eslint-disable key-spacing */
                EXPIRED: "mb-1 text-primary",
                SUCCESS: "mb-1 text-success",
                VALID: "mb-1 text-success",
                FAILED: "mb-1 text-danger",
                CANCELLED: "mb-1 text-warning",
                UNATTEMPTED: "mb-1 text-info",
                /* eslint-enable key-spacing */
            };
            return (status) => statusColor[status];
        },
        statusMessage() {
            const statusColor = {
                /* eslint-disable key-spacing */
                EXPIRED: "EXPIRED",
                SUCCESS: "SUCCESS",
                VALID: "SUCCESS",
                FAILED: "FAILED",
                CANCELLED: "CANCELLED",
                UNATTEMPTED: "UNATTEMPTED",
                /* eslint-enable key-spacing */
            };
            return (status) => statusColor[status];
        },
        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
                return this.downImg
            }
            return this.downImg
        },
    },
    mounted() {
        this.paymentCompleteStatus();
    },
    methods: {
        loginRoute() {
            const user = JSON.parse(localStorage.getItem('userData'))
            return getHomeRouteForLoggedInUser(user ? user.role : null)
        },
        paymentCompleteStatus() {
            console.log(this.$route.query.tran_id)
            try {
                this.$http.get(process.env.VUE_APP_BASEURL+PAYMENT_DETAILS+"?tran_id="+this.$route.query.tran_id)
                    .then(res => {
                       this.paymentCompletedInfo = res.data.data.transaction_details;
                        console.log(res.data.data.transaction_details)
                    });

            } catch (error) {
                console.error(error);
            }
        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
